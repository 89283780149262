import { useAppNavigate } from "@/apollo/cache/auth";
import { CREATE_CONVERSATION } from "@/apollo/messages";
import { CreateConversationMutation, CreateConversationMutationVariables } from "@/apollo/messages/generated/types";
import { Button } from "@/components";
import { useMutation } from "@apollo/client";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";

type Props = { employerId: string; jobId: string; talentId: string };

const MessageButton = ({ employerId, jobId, talentId }: Props) => {
    const [createConversation, { loading }] = useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CREATE_CONVERSATION, { context: { apiName: "message" } });
    const navigate = useAppNavigate();

    const handleOnChatClick = async () => {
        await createConversation({ variables: { employerId, jobId, talentId } }).then(({ data }) => {
            if (data?.createConversation?.id) {
                navigate({
                    to: "/messages",
                    search() {
                        return {conversation_id: data?.createConversation?.id || "" };
                    },
                });
            }
        });
    };

    return (
        <Button size="sm" width="auto" onClick={() => handleOnChatClick()}>
            <div className="flex justify-center items-center space-x-2">
                <ChatBubbleLeftEllipsisIcon className="h-4 w-4" />
                <span>Message</span>
            </div>
        </Button>
    );
};

export default MessageButton;
