import { gql } from "@apollo/client";

export const UPDATE_SUBSCRIPTION = gql`
    mutation UpdatePackage($input: PackageInput!) {
        updatePackage(input: $input)
    }
`;

export const SUBSCRIBE_TO_PACKAGE = gql`
    mutation SubscribeToPackage($packageId: ID!, $paymentReference: String!, $companyId: ID!) {
        subscribeToPackage(packageID: $packageId, payment_reference: $paymentReference, companyId: $companyId)
    }
`;
