import { FC } from "react";
import { DocumentIcon } from "@heroicons/react/24/outline";
import maleImage from "@/assets/images/male.jpeg";
import ViewCandidateButton from "./action-buttons/ViewCandidateButton";
import AcceptCandidateButton from "./action-buttons/AcceptCandidateButton";
import RejectCandidateButton from "./action-buttons/RejectCandidateButton";
import { ApplicationStatus, Rating } from "@/apollo/generated/types";
import HireCandidateButton from "./action-buttons/HireCandidateButton";
import RateCandidateButton from "./action-buttons/RateCandidateButton";
import { Maybe } from "yup";
import { Show } from "@/components";
import { hasPermission, PERMISSIONS } from "@/permissions";
import { useCurrentUserPermissions } from "@/apollo/cache/auth";

type Props = {
    onView: () => void;
    profileImg: string;
    name: string;
    email: string;
    rate?: Maybe<number>;
    resumeLink: string;
    id: string;
    candidateId: string;
    jobId: string;
    status: ApplicationStatus | undefined | null;
    overallStatus: ApplicationStatus | undefined | null;
};

const GridComponent: FC<Props> = ({ onView, profileImg, name, email, rate, candidateId, resumeLink, id, status, jobId,overallStatus }) => {
    const currentUserPermissions = useCurrentUserPermissions()
    return (
        <div className="border border-black/5  rounded-xl px-3 pt-3 space-y-3 bg-white shadow md:max-w-80 w-full">
            {/* name */}
            <div className="flex items-center space-x-2 ">
                <img src={profileImg || maleImage} alt="candidate-profile" className="rounded-full h-10 w-10 object-cover bg-cover" />
                <div className="w-full space-y-1">
                    <h1 className="  leading-4 ">{name}</h1>
                    <h3 className="text-xs text-gray-500 -mt-1">{email}</h3>
                </div>
            </div>
            {/* cv */}
            <div className="flex items-center justify-between space-x-3 bg-gray-100 border border-gray-200 rounded-lg px-3 py-1">
                <span className="p-2 rounded-full bg-primary-500">
                    <DocumentIcon className="h-5 w-5 text-white" />
                </span>
                {resumeLink ? (
                    <div className="flex-1 flex flex-col items-start justify-start">
                        <h1 className="text-xs w-full truncate">{name?.toLowerCase() + ".cv"}</h1>
                        <a href={resumeLink} target="__blank" className="text-xs text-primary-500 underline leading-3 mt-1">
                            View CV
                        </a>
                    </div>
                ) : (
                    <div className="flex-1 text-start">
                        <h1 className="text-xs w-full truncate">No CV</h1>
                    </div>
                )}
            </div>
            {/* Actions */}
            {status === ApplicationStatus.Accepted && (
                <div className="flex justify-between items-center py-3 border-t border-black/5 space-x-10">
                    <div className="flex justify-start items-center space-x-3">
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_HIRE) && overallStatus === ApplicationStatus.Accepted}>
                            <HireCandidateButton applicationId={id} jobId={jobId} status={status} />
                        </Show>
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_REJECT)}>
                            <RejectCandidateButton applicationId={id} jobId={jobId} status={status} />
                        </Show>
                    </div>
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS)}>
                        <ViewCandidateButton type={"grid"} id={candidateId} additionOnClickAction={onView} />
                    </Show>
                </div>
            )}
            {status === ApplicationStatus.Pending && (
                <div className="flex justify-between items-center py-3 border-t border-black/5 space-x-10">
                    <div className="flex justify-start items-center space-x-3">
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_ACCEPT)}>
                            <AcceptCandidateButton applicationId={id} jobId={jobId} status={status} />
                        </Show>
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_REJECT)}>
                            <RejectCandidateButton applicationId={id} jobId={jobId} status={status} />
                        </Show>
                    </div>
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS)}>
                        <ViewCandidateButton type={"grid"} id={candidateId} additionOnClickAction={onView} />
                    </Show>
                </div>
            )}
            {status === ApplicationStatus.Rejected && (
                <div className="flex justify-between items-center py-3 border-t border-black/5 space-x-10">
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_ACCEPT)}>
                        <div className="flex justify-start items-center space-x-3">
                            <AcceptCandidateButton applicationId={id} jobId={jobId} status={status} />
                        </div>
                    </Show>

                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS)}>
                        <ViewCandidateButton type={"grid"} id={candidateId} additionOnClickAction={onView} />
                    </Show>
                </div>
            )}
            {status === ApplicationStatus.Hired && (
                <div className="flex justify-between items-center py-3 border-t border-black/5 space-x-10">
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_RATE)}>
                        <div className="flex justify-start items-center space-x-3">
                            <RateCandidateButton candidateId={id} rate={rate} />
                        </div>
                    </Show>

                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS)}>
                        <ViewCandidateButton type={"grid"} id={candidateId} additionOnClickAction={onView} />
                    </Show>
                </div>
            )}
        </div>
    );
};

export default GridComponent;
