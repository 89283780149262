import {  useCurrentUserPermissions } from "@/apollo/cache/auth";
import { SalaryFrequency } from "@/apollo/generated/types";
import { Button, Show } from "@/components";
import ButtonLoader from "@/components/loaders/button";
import { hasPermission, PERMISSIONS } from "@/permissions";
import { useAppSelector } from "@/redux/hook";
import { selectCreateJob } from "@/redux/job/createJobSlice";
import { formatCurrency, formatPeriod } from "@/utils/formatter";
import { BanknotesIcon, BriefcaseIcon, ClockIcon, GlobeAltIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { FormikProps } from "formik";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";

type Props = {
    form?: FormikProps<any>;
    setActive: (value: "Details" | "Description" | "Preview" | "Pricing") => void;
    handleSubmit: () => void;
    handleDraft: () => void;
    loading: boolean;
    loadingDraft: boolean;
    employerQuota: number;
    activeQuota: number;
    loadingUpdated: boolean;
    draftId: any;
};

const PreviewForm = ({ setActive, handleSubmit, loading, loadingDraft, handleDraft, employerQuota, activeQuota, loadingUpdated, draftId }: Props) => {
    const currentUserPermissions = useCurrentUserPermissions();

    const jobPost = useAppSelector(selectCreateJob);
    return (
        <div className="w-full space-y-5 md:w-5/6">
            <div className="space-y-3 py-5">
                <h1 className="text-2xl font-semibold ">{jobPost?.jobTitle.toUpperCase()}</h1>
                <div className="md:flex md:justify-start md:items-center gap-3  flex-wrap grid  grid-cols-2">
                    {/* comps */}
                    {jobPost?.jobLocation && (
                        <div className="flex text-gray-500 bg-gray-50 items-center justify-center space-x-1 px-2 py-1 rounded  ">
                            <GlobeAltIcon className="h-4 w-4" />
                            <span className="text-[11px] ">{jobPost?.jobLocation}</span>
                        </div>
                    )}
                    {/* comps */}
                    {jobPost?.minimumSalary && jobPost?.maximumSalary ? (
                        <div className="flex  items-center justify-center space-x-1 bg-green-50 px-2 py-1 rounded ">
                            <BanknotesIcon className="h-4 w-4" />
                            <span className="text-[11px] ">
                            {formatCurrency(jobPost?.currency)}{jobPost?.minimumSalary} - {formatCurrency(jobPost?.currency)}{jobPost?.maximumSalary} / {formatPeriod(jobPost?.duration as SalaryFrequency)}
                            </span>
                        </div>
                    ) : (
                        <div className="flex  items-center justify-center space-x-1 bg-green-50 px-2 py-1 rounded ">
                            <BanknotesIcon className="h-4 w-4" />
                            <span className="text-[11px] ">Negotiable</span>
                        </div>
                    )}
                    {/* comps */}
                    {jobPost?.jobType && (
                        <div className="flex  items-center justify-center space-x-1 bg-gray-50 px-2 py-1 rounded  ">
                            <BriefcaseIcon className="h-4 w-4" />
                            <span className="text-[11px] ">{jobPost?.jobType}</span>
                        </div>
                    )}
                    {/* comps */}
                    {jobPost?.modeOfWork && (
                        <div className="flex  items-center justify-center space-x-1 bg-gray-50 px-2 py-1 rounded  ">
                            <MapPinIcon className="h-4 w-4" />
                            <span className="text-[11px] ">{jobPost?.modeOfWork}</span>
                        </div>
                    )}
                    {/* comp */}
                    {jobPost.openingDate && (
                        <div className="flex text-gray-500 items-center justify-center space-x-1 px-2 py-1 rounded  bg-blue-50">
                            <ClockIcon className="h-4 w-4" />
                            <span className="text-[11px] ">Opening : {moment(jobPost.openingDate).format("MMMM Do, YYYY")}</span>
                        </div>
                    )}
                    {/* comps */}
                    {jobPost.closingDate && (
                        <div className="flex  items-center justify-center space-x-1 bg-red-50 px-2 py-1 rounded ">
                            <ClockIcon className="h-4 w-4" />
                            <span className="text-[11px] ">Closing : {moment(jobPost.closingDate).format("MMMM Do, YYYY")}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className="space-y-1">
                <h2 className="text-base font-semibold">About This Job</h2>

                <div
                    dangerouslySetInnerHTML={{
                        __html: jobPost?.about,
                    }}
                    className="text-sm font-light text-gray-600 description"
                />
            </div>
            <div className="space-y-1">
                <h2 className="text-base font-semibold">Responsibilities</h2>

                <div
                    dangerouslySetInnerHTML={{
                        __html: jobPost?.responsibilities,
                    }}
                    className="text-sm font-light text-gray-600 description"
                />
            </div>
            <div className="space-y-1">
                <h2 className="text-base font-semibold">Requirements/Qualifications</h2>

                <div
                    dangerouslySetInnerHTML={{
                        __html: jobPost?.requirements,
                    }}
                    className="text-sm font-light text-gray-600 description"
                />
            </div>

            {jobPost?.benefits && jobPost?.benefits?.length > 11 && (
                <div className="space-y-1">
                    <h2 className="text-base font-semibold">Benefits</h2>

                    <div
                        dangerouslySetInnerHTML={{
                            __html: jobPost?.benefits,
                        }}
                        className="text-sm font-light text-gray-600 description"
                    />
                </div>
            )}

            <div className="col-span-full flex items-center justify-between space-x-5">
                <div>
                    <Button variant="minimal" size="md" onClick={() => setActive("Description")}>
                        Previous
                    </Button>
                </div>
                <div className="flex justify-end items-center space-x-5">
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_DRAFT_POST)}>
                        {_.isEmpty(draftId) ? (
                            <div>
                                <Button
                                    variant="minimal"
                                    size="md"
                                    onClick={() => {
                                        handleDraft();
                                    }}>
                                    {loadingDraft ? <ButtonLoader title="Saving..." color="text-primary-500" /> : "Save as draft"}
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Button
                                    variant="minimal"
                                    size="md"
                                    onClick={() => {
                                        handleDraft();
                                    }}>
                                    {loadingUpdated ? <ButtonLoader title="Updating..." color="text-primary-500" /> : "Update draft"}
                                </Button>
                            </div>
                        )}
                    </Show>
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_CREATE)}>
                        <div>
                            {employerQuota > activeQuota ? (
                                <Button variant="primary" size="md" onClick={() => handleSubmit()}>
                                    <span className="flex justify-between items-center space-x-2">
                                        <span>{loading ? <ButtonLoader title="Posting ... " /> : "Post Job"}</span>
                                    </span>
                                </Button>
                            ) : (
                                <Button variant="minimal" disabled={true}>
                                    <span className="text-xs text-red-400 font-medium">Active job quota exceeded</span>
                                </Button>
                            )}
                        </div>
                    </Show>
                </div>
            </div>
        </div>
    );
};

export default PreviewForm;
