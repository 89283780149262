import { Fragment, Suspense, useEffect, useState } from "react";
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, Transition, TransitionChild } from "@headlessui/react";
import { TbLogout } from "react-icons/tb";
import logo from "@/assets/images/logo.png";
import maleImage from "@/assets/images/male.jpeg";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "@/utils";
import { Link, Navigate, Outlet, useNavigate } from "react-location";
import SignoutModal from "../components/signout";
import { clearAuth, currentUserPermissionVar, useCurrentToken, useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import routes from "@/router/routes";
import { LocationGenerics } from "@/router/location";
import LoadingComponent from "@/components/loaders/top-loader";
import Show from "@/components/core/show";
import _ from "lodash";
import { IoIosArrowForward } from "react-icons/io";
import Avatar from "@/components/core/avatar";
import { hasPermission, Permission } from "@/permissions";

export default function AppLayout() {
    const navigate = useNavigate<LocationGenerics>();

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const employer = useCurrentUser();
    const currentUserPermissions = currentUserPermissionVar();
    const currentToken = useCurrentToken();

    useEffect(() => {
        if (!currentToken || !employer?.id || currentUserPermissions.length <= 2) {
            clearAuth()
            navigate({
                to: "/login",
                search(prev) {
                    return { ...prev };
                },
            });
        }
    }, [currentToken, employer?.id]);

    return (
        <>
            <div className="w-screen h-screen mx-auto relative">
                <div className="fixed inset-x-0 top-0 z-40 flex justify-center border-b border-gray-200 h-16 bg-white">
                    <div className="w-full h-full sm:w-11/12 xl:w-5/6 flex justify-between items-center">
                        <div className="flex justify-start items-center space-x-2 px-3">
                            {" "}
                            <button type="button" className="text-gray-700 md:hidden" onClick={() => setSidebarOpen(true)}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                            <Link to="/" className="flex h-16 shrink-0 items-center">
                                <img className="h-8 w-auto " src={logo} alt="Adjuma Logo" />
                            </Link>
                        </div>
                        <div className="flex flex-1 gap-x-4 justify-end md:gap-x-6">
                            <div className="flex items-center gap-x-4 md:gap-x-6">
                                {/* Profile dropdown */}
                                <Menu as="div" className="relative">
                                    <MenuButton className="mr-3 flex items-center py-1.5">
                                        <span className="sr-only">Open user menu</span>
                                        <Avatar src={`${employer?.profilePicture || ""}`} alt={`${employer?.fullName || ""}`} size="sm" />

                                        {/*  */}
                                        <span className="hidden md:flex md:items-center">
                                            <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </MenuButton>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95">
                                        <MenuItems className="absolute right-0 z-10 mt-2.5 w-52  origin-top-right rounded-md bg-white py-2 shadow-xl ring-1 ring-gray-900/5 focus:outline-none">
                                            <MenuItem>
                                                {() => (
                                                    <div className={classNames("block px-3 py-1 text-sm leading-6 text-gray-900")}>
                                                        <div className="flex flex-col items-center justify-center gap-x-2 py-3">
                                                            <Avatar src={`${employer?.profilePicture || ""}`} alt={`${employer?.fullName || ""}`} size="sm" />
                                                            <div className="w-full  flex flex-col justify-center text-center">
                                                                <h4 className="font-montserrat font-medium text-sm w-full  text-neutral-600 tracking-[-0.5%] truncate ">{employer?.fullName ?? "Full Name"}</h4>
                                                                <p className="font-montserrat font-medium text-neutral-400 text-xs tracking-[-0.5%] truncate ">{employer?.email ?? "example@gmail.com"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </MenuItem>
                                            <MenuItem>
                                                {({ active }) => (
                                                    <Link to="/profile" className={classNames(active ? "bg-gray-50" : "", "block  text-xs  text-gray-900 px-4 border-t border-b border-[#CBD5E1]")}>
                                                        <div className="flex cursor-pointer  justify-between items-center py-3">
                                                            <span className="text-sm text-gray-700 font-medium leading-4">View Profile</span>
                                                            <IoIosArrowForward className="text-[#CBD5E1] w-5 h-5" aria-hidden="true" />
                                                        </div>
                                                    </Link>
                                                )}
                                            </MenuItem>
                                            <MenuItem>
                                                {({ active }) => (
                                                    <div className={classNames(active ? "bg-gray-50" : "", "block  text-xs  text-gray-900 px-2")}>
                                                        <div onClick={() => setModalVisible(true)} className="flex text-red-500 cursor-pointer items-center justify-start space-x-3 py-3  px-3 w-full">
                                                            <TbLogout className="w-5 h-5 " aria-hidden="true" />
                                                            <span className="font-montserrat font-medium text-sm leading-4">Sign out</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </MenuItem>
                                        </MenuItems>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative w-full lg:w-5/6 flex justify-between items-start m-auto h-screen">
                    <Transition show={sidebarOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                            <TransitionChild as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <div className="fixed inset-0 bg-gray-900/80" />
                            </TransitionChild>

                            <div className="fixed inset-0 flex">
                                <TransitionChild as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
                                    <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                                        <TransitionChild as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                                            <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                                <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                    <span className="sr-only">Close sidebar</span>
                                                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </TransitionChild>
                                        {/* Sidebar component, swap this element with another sidebar if you like */}
                                        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                            <Link to="/" className="flex lg:hidden h-16 shrink-0 items-center">
                                                <img className="h-7 w-auto" src={logo} alt="Adjuma Logo" />
                                            </Link>
                                            <nav className="flex flex-1 flex-col">
                                                <div role="list" className="flex flex-1 flex-col gap-y-7">
                                                    <div>
                                                        <ul role="list" className="-mx-2 space-y-1" key={29}>
                                                            {routes
                                                                .filter((route) => route.meta?.layout === "App" && !_.isEmpty(route.sidebar?.label))
                                                                .map((item, index) => {
                                                                    if ((item.withPermissions?.length || 0) > 0) {
                                                                        if (hasPermission(currentUserPermissions, item.withPermissions?.at(0) as Permission)) {
                                                                            return (
                                                                                <li
                                                                                    key={item.sidebar?.label || index}
                                                                                    onClick={() => {
                                                                                        navigate({ to: `${item.path}` });
                                                                                        setSidebarOpen(false);
                                                                                    }}>
                                                                                    <button
                                                                                        className={classNames(
                                                                                            item.path === (item.path !== "/" ? location.pathname.slice(1) : location.pathname) ? "bg-gray-50 text-black" : "text-gray-700 hover:text-black hover:bg-gray-50",
                                                                                            "group flex gap-x-3 rounded-md w-full p-2 text-sm leading-6 font-semibold"
                                                                                        )}>
                                                                                        {item.sidebar && (
                                                                                            <span className="relative ">
                                                                                                <Show if={false}>
                                                                                                    <span className="rounded-full absolute -top-2 -right-2  text-[9px] truncate bg-primary-500 text-white h-4 w-4 flex-center">20</span>
                                                                                                </Show>
                                                                                                <item.sidebar.icon
                                                                                                    className={classNames(item.path === (item.path !== "/" ? location.pathname.slice(1) : location.pathname) ? "text-black font-bold" : "text-gray-400 group-hover:text-black", "h-6 w-6 shrink-0")}
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </span>
                                                                                        )}
                                                                                        <span className="md:hidden lg:flex">{item.sidebar?.label}</span>
                                                                                    </button>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <li
                                                                                key={item.sidebar?.label || index}
                                                                                onClick={() => {
                                                                                    navigate({ to: `${item.path}` });
                                                                                    setSidebarOpen(false);
                                                                                }}>
                                                                                <button
                                                                                    className={classNames(
                                                                                        item.path === (item.path !== "/" ? location.pathname.slice(1) : location.pathname) ? "bg-gray-50 text-black" : "text-gray-700 hover:text-black hover:bg-gray-50",
                                                                                        "group flex w-full gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                                                    )}>
                                                                                    {item.sidebar && (
                                                                                        <span className="relative ">
                                                                                            <Show if={false}>
                                                                                                <span className="rounded-full absolute -top-2 -right-2  text-[9px] truncate bg-primary-500 text-white h-4 w-4 flex-center">20</span>
                                                                                            </Show>
                                                                                            <item.sidebar.icon
                                                                                                className={classNames(item.path === (item.path !== "/" ? location.pathname.slice(1) : location.pathname) ? "text-black font-bold" : "text-gray-400 group-hover:text-black", "h-6 w-6 shrink-0")}
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </span>
                                                                                    )}
                                                                                    <span className="md:hidden lg:flex">{item.sidebar?.label}</span>
                                                                                </button>
                                                                            </li>
                                                                        );
                                                                    }
                                                                })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </Dialog>
                    </Transition>

                    {/* Static sidebar for desktop */}
                    <div className="hidden h-screen md:flex md:w-16 lg:w-48 md:flex-col xl:w-72  pt-5 lg:pt-16">
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 md:pt-16 lg:pt-5">
                            <nav className="flex flex-1 flex-col">
                                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                    <li>
                                        <ul role="list" className="-mx-2 space-y-1">
                                            {routes
                                                .filter((route) => route.meta?.layout === "App" && !_.isEmpty(route.sidebar?.label))
                                                .map((item, index) => {
                                                    if ((item.withPermissions?.length || 0) > 0) {
                                                        if (hasPermission(currentUserPermissions, item.withPermissions?.at(0) as Permission)) {
                                                            return (
                                                                <li key={item.sidebar?.label || index}>
                                                                    <Link
                                                                        to={`${item.path}`}
                                                                        className={classNames(
                                                                            item.path === (item.path !== "/" ? location.pathname.slice(1) : location.pathname) ? "bg-gray-50 text-black" : "text-gray-700 hover:text-black hover:bg-gray-50",
                                                                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                                        )}>
                                                                        {item.sidebar && (
                                                                            <span className="relative ">
                                                                                <Show if={false}>
                                                                                    <span className="rounded-full absolute -top-2 -right-2  text-[9px] truncate bg-primary-500 text-white h-4 w-4 flex-center">20</span>
                                                                                </Show>
                                                                                <item.sidebar.icon
                                                                                    className={classNames(item.path === (item.path !== "/" ? location.pathname.slice(1) : location.pathname) ? "text-black font-bold" : "text-gray-400 group-hover:text-black", "h-6 w-6 shrink-0")}
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        )}
                                                                        <span className="md:hidden lg:flex">{item.sidebar?.label}</span>
                                                                    </Link>
                                                                </li>
                                                            );
                                                        }
                                                    } else {
                                                        return (
                                                            <li key={item.sidebar?.label || index}>
                                                                <Link
                                                                    to={`${item.path}`}
                                                                    className={classNames(
                                                                        item.path === (item.path !== "/" ? location.pathname.slice(1) : location.pathname) ? "bg-gray-50 text-black" : "text-gray-700 hover:text-black hover:bg-gray-50",
                                                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                                    )}>
                                                                    {item.sidebar && (
                                                                        <span className="relative ">
                                                                            <Show if={false}>
                                                                                <span className="rounded-full absolute -top-2 -right-2  text-[9px] truncate bg-primary-500 text-white h-4 w-4 flex-center">20</span>
                                                                            </Show>
                                                                            <item.sidebar.icon
                                                                                className={classNames(item.path === (item.path !== "/" ? location.pathname.slice(1) : location.pathname) ? "text-black font-bold" : "text-gray-400 group-hover:text-black", "h-6 w-6 shrink-0")}
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                    <span className="md:hidden lg:flex">{item.sidebar?.label}</span>
                                                                </Link>
                                                            </li>
                                                        );
                                                    }
                                                })}
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div className="flex-1  overflow-scroll overflow-y-scroll h-screen no-scrollbar">
                        <main className="w-full h-full ">
                            <div className="w-full relative">
                                <Suspense fallback={LoadingComponent()}>
                                    <Outlet />
                                </Suspense>
                            </div>
                        </main>
                    </div>
                </div>
            </div>

            <SignoutModal
                visible={modalVisible}
                onClose={() => {
                    setModalVisible(false);
                }}
            />
        </>
    );
}
