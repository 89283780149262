import React, { useEffect, useState } from "react";
import { useConversations } from "@/hooks/useConversations";
import { useCurrentUser } from "@/apollo/cache/auth";
import { useUrlState } from "@/utils";
import _ from "lodash";
import { Conversation, ConversationUpdatedSubscription, ConversationUpdatedSubscriptionVariables, MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables, MessageAddedSubscription, MessageAddedSubscriptionVariables, MessageStatus } from "@/apollo/messages/generated/types";
import moment from "moment";
import { useMutation, useSubscription } from "@apollo/client";
import { CONVERSATIONS_UPDATED, MARK_ALL_AS_READ } from "@/apollo/messages";
import List from "@/components/core/list";
import Avatar from "@/components/core/avatar";
import { PiCheckThin, PiChecksThin } from "react-icons/pi";
import notificationPic from "@/assets/images/no-notif.svg";

const ChatList: React.FC = ({}) => {
    const [selectedChat, setSelectedChat] = useUrlState("conversation_id");
    const employer = useCurrentUser();

    const { conversations, fetchMore } = useConversations({ variables: { pagination: {}, userId: employer?.id || "" } });
    const { data } = useSubscription<ConversationUpdatedSubscription, ConversationUpdatedSubscriptionVariables>(CONVERSATIONS_UPDATED);

    const [markAllAsRead] = useMutation<MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables>(MARK_ALL_AS_READ, { refetchQueries: ["GetCoversations"], context: { apiName: "message" } });

    const handleOnClick = async (id: string) => {
        setSelectedChat(id);
        await markAllAsRead({ variables: { conversationId: id, sentBy: "employer" } });
    };

    useEffect(() => {
        fetchMore({
            variables: {
                pagination: {},
                userId: employer?.id || "",
            },
        });
    }, [data?.conversationUpdated]);
    return (
        <div className="h-full overflow-auto">
            <List
                data={conversations || []}
                renderItem={(chat: Conversation) => (
                    <div key={chat?.id} className={`flex justify-between items-center space-x-2  py-3 px-2 border-b cursor-pointer ${chat?.id === selectedChat ? " mobile:bg-transparent bg-[#EBFFEB]" : ""}`} onClick={() => handleOnClick(chat?.id || "")}>
                        <div className="">
                            <Avatar src={chat?.talent?.profilePicture || ""} alt={chat?.talent?.fullName || ""} />
                        </div>
                        <div className="flex flex-1 overflow-hidden justify-between  border-gray-300">
                            <div className="w-full overflow-hidden">
                                <p className="font-montserrat font-semibold text-sm leading-4 ">{chat?.talent?.fullName} </p>
                                <p className="font-montserrat text-sm text-gray-400 whitespace-nowrap overflow-hidden">{chat?.lastMessageDetails?.message}</p>
                            </div>

                            <div className="flex flex-col items-end pr-4">
                                <p className="text-[10px] whitespace-nowrap mb-1 text-gray-400 ">{moment(chat?.lastMessageDetails?.createdAt).fromNow()}</p>
                                {(chat?.numberOfUnreadMessagesByEmployer || 0) > 0 ? (
                                    <div className="font-manrope font-medium w-5 h-5 rounded-full bg-primary-500 flex justify-center items-center text-white text-[10px] leading-3">{chat?.numberOfUnreadMessagesByEmployer || 0}</div>
                                ) : chat?.lastMessageDetails?.status === MessageStatus.Read ? (
                                    <PiCheckThin className="h-4 w-4 text-gray-500" />
                                ) : chat?.lastMessageDetails?.status === MessageStatus.Delivered ? (
                                    <PiChecksThin className="h-4 w-4 text-gray-500" />
                                ) : chat?.lastMessageDetails?.status === MessageStatus.Sent ? (
                                    <PiChecksThin className="h-4 w-4 text-primary-500" />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                renderEmptyComponent={() => (
                    <div className="w-full h-full min-h-full flex items-center justify-center pt-32 px-5">
                        <div className="flex flex-col items-center w-full pt-10 gap-y-5">
                            <img src={notificationPic} alt="no notification picture" className="w-60 h-36 object-contain" />
                            <h4 className="font-semibold text-base text-black">No conversations found</h4>
                            <p className="text-sm text-center text-gray-600 max-w-[23.563rem]">You do not have any conversation. Choose a candidate and start messaging now</p>
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

export default ChatList;
