import { Button, GooglePlaces, Show, TextInput } from "@/components";
import { MinusIcon } from "@heroicons/react/24/outline";
import { RangeSlider } from "@mantine/core";
import { FormikProps } from "formik";
import classes from "@/assets/css/rangeSlider.module.css";
import { useEffect, useState } from "react";
import SelectInput from "../components/SelectInput";
import _ from "lodash";
import { selectCreateJob } from "@/redux/job/createJobSlice";
import { useAppSelector } from "@/redux/hook";
import ProgressBar from "../components/ProgressBar";
import { CurrencyOption, JobTypeOption, ModeOfWorkOption, SalaryDurationOption } from "@/data";
import { hasPermission, PERMISSIONS } from "@/permissions";
import { useCurrentUserPermissions } from "@/apollo/cache/auth";

type Props = { form: FormikProps<any>; handleClear: () => void };

const DetailForm = ({ form, handleClear }: Props) => {
    const currentUserPermissions = useCurrentUserPermissions()
    const jobPost = useAppSelector(selectCreateJob);
    const [rangeValue, setRangeValue] = useState<[number, number]>([0, 0]);

    useEffect(() => {
        form.setValues({ ...jobPost, salaryRange: jobPost.minimumSalary ? true : false });
    }, []);

    useEffect(() => {
        setRangeValue([Number(form.values.minimumSalary), Number(form.values.maximumSalary)]);
    }, [form.values.minimumSalary, form.values.maximumSalary]);

    return (
        <div className="w-full ">
            <div className="w-full md:w-5/6 mb-10 flex justify-between items-center">
                <div>
                    <h1 className="font-bold text-xl">Job Details</h1>
                    <p className="text-sm text-gray-500 mt-1">Fill in the fields with the necessary information.</p>
                </div>
                <div>
                    <ProgressBar />
                </div>
            </div>
            <div className="w-full md:w-5/6 grid md:grid-cols-2 grid-cols-1 gap-5 md:gap-7 lg:gap:10 ">
                <div>
                    <TextInput id={"jobTitle"} label="Job Title" {...form} required />
                </div>
                <div>
                    <SelectInput label="Job Type" value={JobTypeOption.filter((option) => option.label === form.values.jobType)?.at(0) || { value: "", label: "" }} required options={JobTypeOption} onSelected={(option) => form.setFieldValue("jobType", option.value)} />
                </div>
                <div>
                    <SelectInput value={ModeOfWorkOption.filter((option) => option.value === form.values.modeOfWork)?.at(0) || { value: "", label: "" }} label="Mode of Work" required options={ModeOfWorkOption} onSelected={(option) => form.setFieldValue("modeOfWork", option.value)} />
                </div>
                <div className="w-full lg:w-full flex flex-col">
                    <label htmlFor="jobLocation" className=" block text-sm font-medium text-gray-700 mb-1">
                        Location <span className="text-primary-500">*</span>
                    </label>
                    <div className="flex  relative items-center">
                        <GooglePlaces field="jobLocation" placeholder="" formik={form} className=" w-full bg-white  text-sm  py-  sm:leading-6 pl-2 " />
                    </div>
                    {form.errors.jobLocation && (
                        <p className="mt-2 text-sm text-red-600">
                            <>{form.errors.jobLocation}</>
                        </p>
                    )}
                </div>
                <div>
                    <TextInput
                        id={"openingDate"}
                        label={
                            <span>
                                Job <span className="text-primary-500">Opening</span> Date
                            </span>
                        }
                        type="date"
                        {...form}
                        required
                    />
                </div>
                <div>
                    <TextInput
                        id={"closingDate"}
                        label={
                            <span>
                                Job <span className="text-red-500">Closing</span> Date
                            </span>
                        }
                        type="date"
                        {...form}
                        required
                    />
                </div>
                <div>
                    <TextInput id={"vacancies"} label={"Number of Openings"} type="number" {...form} />
                </div>
                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_URGENT)}>
                    <div className="flex items-start justify-start space-x-2 pt-5">
                        <span className="">
                            <input id={"urgent"} type="checkbox" checked={form.values.urgent} onChange={() => form.setFieldValue("urgent", !form.values.urgent)} className="bg-primary-500 accent-primary-500 ring-primary-500" />
                        </span>
                        <div className=" flex flex-col item-start justify-start w-full">
                            <span className="text-xs font-semibold">Mark as Urgent</span>
                            <span className="text-[11px] ">Tick if you want this job to be filled urgently. This will enable the job to appear on the top of search.</span>
                        </div>
                    </div>
                </Show>
                <div className="col-span-full grid md:grid-cols-2 grid-cols-1 gap-5 md:gap-7 lg:gap:10 ">
                    <div className="flex items-start justify-start space-x-2">
                        <span className="">
                            <input id={"salaryRange"} type="checkbox" checked={form.values.salaryRange} onChange={() => form.setFieldValue("salaryRange", !form.values.salaryRange)} className="bg-primary-500 accent-primary-500 ring-primary-500" />
                        </span>
                        <div className=" flex flex-col item-start justify-start w-full">
                            <span className="text-xs font-semibold">Include Salary Range</span>
                            <span className="text-[11px] ">Tick if you want to include range of job post</span>
                        </div>
                    </div>
                    <Show if={form.values.salaryRange}>
                        <div className="space-y-3 w-full  ">
                            <div className="flex items-center justify-between space-x-2">
                                <div className="text-xs min-w-56">
                                    <SelectInput options={SalaryDurationOption} value={SalaryDurationOption.filter((option) => option.value === form.values.duration)?.at(0) || { value: "", label: "" }} noRing={true} onSelected={(option) => form.setFieldValue("duration", option.value)} />
                                </div>
                                <div className=" text-xs min-w-36">
                                    <SelectInput
                                        options={CurrencyOption}
                                        value={CurrencyOption.filter((option) => option.value === form.values.currency)?.at(0) || { value: "", label: "" }}
                                        noRing={true}
                                        onSelected={(option) => {
                                            form.setFieldValue("currency", option.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-full pt-5">
                                <RangeSlider
                                    min={0}
                                    max={30000}
                                    minRange={100}
                                    defaultValue={[100, 500]}
                                    step={100}
                                    value={rangeValue}
                                    onChange={(val) => {
                                        setRangeValue(val);
                                        form.setFieldValue("minimumSalary", val[0]);
                                        form.setFieldValue("maximumSalary", val[1]);
                                    }}
                                    color="teal"
                                    labelAlwaysOn
                                    classNames={classes}
                                />
                            </div>
                            <div className="w-full flex items-center justify-between space-x-3">
                                <div>
                                    <label htmlFor="" className="block text-xs font-medium text-gray-700">
                                        Minimum
                                    </label>
                                    <input
                                        className="border-2 w-28 text-xs h-8 px-2 outline-primary-500 rounded  border-black/5"
                                        type="number"
                                        placeholder="Enter min"
                                        value={form.values?.minimumSalary}
                                        min={0}
                                        max={rangeValue[1] - 100}
                                        onChange={(e) => {
                                            setRangeValue((prev) => {
                                                const val = [Number(e.target.value), prev[1]];
                                                return val as [number, number];
                                            });
                                            form.setFieldValue("minimumSalary", e.target.value);
                                        }}
                                    />
                                    {form?.errors?.minimumSalary && (
                                        <p className="text-xs text-red-500">
                                            <>{form?.errors?.minimumSalary}</>
                                        </p>
                                    )}
                                </div>
                                <MinusIcon className="h-4 w-4 " />{" "}
                                <div>
                                    <label htmlFor="" className="block text-xs font-medium text-gray-700">
                                        Maximum
                                    </label>
                                    <input
                                        className="border-2 w-28 text-xs h-8 px-2 outline-primary-500 rounded border-black/5"
                                        type="number"
                                        placeholder="Enter max"
                                        min={rangeValue[0]}
                                        max={30000}
                                        value={form.values.maximumSalary}
                                        onChange={(e) => {
                                            setRangeValue((prev) => {
                                                const val = [prev[0], Number(e.target.value)];
                                                return val as [number, number];
                                            });
                                            form.setFieldValue("maximumSalary", e.target.value);
                                        }}
                                    />
                                    {form?.errors?.maximumSalary && (
                                        <p className="text-xs text-red-500">
                                            <>{form?.errors?.maximumSalary}</>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Show>
                </div>
                <div className="col-span-full flex items-center justify-between space-x-5">
                    <div>
                        <Button variant="minimal" size="md" width="auto" onClick={() => handleClear()}>
                            <span className="text-primary-500">Clear</span>
                        </Button>
                    </div>
                    <div>
                        <Button variant="primary" size="md" onClick={() => form.handleSubmit()} width="auto">
                            <span className="flex justify-between items-center space-x-2">
                                <span className="px-5">Next</span>
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailForm;
