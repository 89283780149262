import React, { FC } from "react";

type Props = {
    offset: number;
    limit: number;
    totalItems: number;
    currentSize: number;
    setOffset: (value: any) => void;
};

const Paginator: FC<Props> = ({ offset, limit, totalItems, setOffset, currentSize }) => {
    // calculate current page
    const currentPage = Math.ceil(Number(offset) / Number(limit)) + 1;

    return (
        <nav className="bg-white px-4 py-5 flex  items-center justify-between border-t rounded-b-md border-gray-200 sm:px-6 justify-self-start flex-shrink-0" aria-label="Pagination">
            <div className=" flex justify-between sm:justify-end">
                <button
                    disabled={Number(offset) < 1}
                    onClick={() => setOffset(currentPage - 1)}
                    className="relative inline-flex items-center text-sm  disabled:cursor-not-allowed px-3 py-1 border border-gray-300 font-medium rounded-md text-gray-700 disabled:text-gray-300 bg-white hover:bg-gray-50">
                    Previous
                </button>
                <button
                    disabled={Number(totalItems) < Number(limit) || Number(offset) + Number(currentSize) === Number(totalItems)}
                    onClick={() => setOffset(currentPage + 1)}
                    className="ml-3 relative inline-flex items-center  disabled:cursor-not-allowed px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 disabled:text-gray-300 bg-white hover:bg-gray-50">
                    Next
                </button>
            </div>
            <div className="flex flex-1  justify-end">
                <p className="text-sm text-gray-700">
                    Page <span className="font-medium">{Number(offset) + 1}</span> to <span className="font-medium">{Number(offset) + Number(currentSize)}</span> of{" "}
                    <span className="font-medium">{Number(totalItems)}</span> results
                </p>
            </div>
        </nav>
    );
};

export default Paginator;
