import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Email: { input: any; output: any; }
  Token: { input: any; output: any; }
};

export type Conversation = {
  __typename?: 'Conversation';
  createdAt?: Maybe<Scalars['Date']['output']>;
  employer?: Maybe<User>;
  id?: Maybe<Scalars['ID']['output']>;
  jobId?: Maybe<Scalars['ID']['output']>;
  lastMessageDetails?: Maybe<Message>;
  numberOfUnreadMessagesByEmployer?: Maybe<Scalars['Int']['output']>;
  numberOfUnreadMessagesByTalent?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  talent?: Maybe<User>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type DateOperator = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<Scalars['Date']['input']>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  notIn?: InputMaybe<Array<Scalars['Date']['input']>>;
};

export type IdOperator = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type IntOperator = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Job = {
  __typename?: 'Job';
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employer?: Maybe<User>;
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type Message = {
  __typename?: 'Message';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  messageType?: Maybe<MessageType>;
  parentMessage?: Maybe<Message>;
  read: Scalars['Boolean']['output'];
  sentBy: Scalars['String']['output'];
  status?: Maybe<MessageStatus>;
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<User>;
};

export enum MessageStatus {
  Delivered = 'delivered',
  Read = 'read',
  Sent = 'sent'
}

export enum MessageType {
  Forward = 'forward',
  New = 'new',
  Reply = 'reply'
}

export type Mutation = {
  __typename?: 'Mutation';
  clearChatHistory?: Maybe<Scalars['Boolean']['output']>;
  createConversation?: Maybe<Conversation>;
  deleteConversation?: Maybe<Scalars['Boolean']['output']>;
  markAllMessagesAsRead?: Maybe<Scalars['Boolean']['output']>;
  markMessageAsRead?: Maybe<Message>;
  reportConversation?: Maybe<Scalars['Boolean']['output']>;
  sendMessage?: Maybe<Message>;
};


export type MutationClearChatHistoryArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationCreateConversationArgs = {
  employerId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  talentId: Scalars['ID']['input'];
};


export type MutationDeleteConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationMarkAllMessagesAsReadArgs = {
  conversationId: Scalars['ID']['input'];
  sentBy: Scalars['String']['input'];
};


export type MutationMarkMessageAsReadArgs = {
  messageId: Scalars['ID']['input'];
  sentBy: Scalars['String']['input'];
};


export type MutationReportConversationArgs = {
  conversationId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};


export type MutationSendMessageArgs = {
  conversationId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  parentMessageId?: InputMaybe<Scalars['ID']['input']>;
  senderId: Scalars['ID']['input'];
  sentBy: Scalars['String']['input'];
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  __typename?: 'Query';
  getConversation?: Maybe<Conversation>;
  getConversations?: Maybe<Array<Maybe<Conversation>>>;
  getMessages?: Maybe<Array<Maybe<Message>>>;
};


export type QueryGetConversationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetConversationsArgs = {
  pagination: Pagination;
  userId: Scalars['ID']['input'];
};


export type QueryGetMessagesArgs = {
  conversationId: Scalars['ID']['input'];
  pagination?: InputMaybe<Pagination>;
};

export type StringOperator = {
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  conversationCreated?: Maybe<Array<Maybe<Conversation>>>;
  conversationDeleted?: Maybe<Array<Maybe<Conversation>>>;
  conversationUpdated?: Maybe<Array<Maybe<Conversation>>>;
  messageAdded?: Maybe<Array<Maybe<Message>>>;
  messageRead?: Maybe<Array<Maybe<Message>>>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['Email']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  profilePicture?: Maybe<Scalars['String']['output']>;
};

export type GetConversationsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  pagination: Pagination;
}>;


export type GetConversationsQuery = { __typename?: 'Query', getConversations?: Array<{ __typename?: 'Conversation', id?: string | null, numberOfUnreadMessagesByEmployer?: number | null, jobId?: string | null, status?: string | null, createdAt?: any | null, updatedAt?: any | null, talent?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, employer?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, lastMessageDetails?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null } | null> | null };

export type GetConversationQueryVariables = Exact<{
  getConversationId: Scalars['ID']['input'];
}>;


export type GetConversationQuery = { __typename?: 'Query', getConversation?: { __typename?: 'Conversation', id?: string | null, numberOfUnreadMessagesByEmployer?: number | null, jobId?: string | null, status?: string | null, createdAt?: any | null, updatedAt?: any | null, talent?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, employer?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, lastMessageDetails?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null } | null };

export type GetMessagesQueryVariables = Exact<{
  conversationId: Scalars['ID']['input'];
  pagination?: InputMaybe<Pagination>;
}>;


export type GetMessagesQuery = { __typename?: 'Query', getMessages?: Array<{ __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null> | null };

export type ClearChatHistoryMutationVariables = Exact<{
  conversationId: Scalars['ID']['input'];
}>;


export type ClearChatHistoryMutation = { __typename?: 'Mutation', clearChatHistory?: boolean | null };

export type DeleteConversationMutationVariables = Exact<{
  conversationId: Scalars['ID']['input'];
}>;


export type DeleteConversationMutation = { __typename?: 'Mutation', deleteConversation?: boolean | null };

export type ReportConversationMutationVariables = Exact<{
  conversationId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
}>;


export type ReportConversationMutation = { __typename?: 'Mutation', reportConversation?: boolean | null };

export type MarkMessageAsReadMutationVariables = Exact<{
  messageId: Scalars['ID']['input'];
  sentBy: Scalars['String']['input'];
}>;


export type MarkMessageAsReadMutation = { __typename?: 'Mutation', markMessageAsRead?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null };

export type MarkAllMessagesAsReadMutationVariables = Exact<{
  conversationId: Scalars['ID']['input'];
  sentBy: Scalars['String']['input'];
}>;


export type MarkAllMessagesAsReadMutation = { __typename?: 'Mutation', markAllMessagesAsRead?: boolean | null };

export type SendMessageMutationVariables = Exact<{
  conversationId: Scalars['ID']['input'];
  senderId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  sentBy: Scalars['String']['input'];
  parentMessageId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null };

export type CreateConversationMutationVariables = Exact<{
  talentId: Scalars['ID']['input'];
  employerId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}>;


export type CreateConversationMutation = { __typename?: 'Mutation', createConversation?: { __typename?: 'Conversation', id?: string | null, jobId?: string | null, status?: string | null, createdAt?: any | null, updatedAt?: any | null, talent?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, employer?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, lastMessageDetails?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null } | null };

export type ConversationDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConversationDeletedSubscription = { __typename?: 'Subscription', conversationDeleted?: Array<{ __typename?: 'Conversation', id?: string | null, jobId?: string | null, status?: string | null, createdAt?: any | null, updatedAt?: any | null, talent?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, employer?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, lastMessageDetails?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null } | null> | null };

export type ConversationCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConversationCreatedSubscription = { __typename?: 'Subscription', conversationCreated?: Array<{ __typename?: 'Conversation', id?: string | null, jobId?: string | null, status?: string | null, createdAt?: any | null, updatedAt?: any | null, talent?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, employer?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, lastMessageDetails?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null } | null> | null };

export type ConversationUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConversationUpdatedSubscription = { __typename?: 'Subscription', conversationUpdated?: Array<{ __typename?: 'Conversation', id?: string | null, jobId?: string | null, status?: string | null, createdAt?: any | null, updatedAt?: any | null, talent?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, employer?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, lastMessageDetails?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null } | null> | null };

export type MessageAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MessageAddedSubscription = { __typename?: 'Subscription', messageAdded?: Array<{ __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null> | null };

export type MessageReadSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MessageReadSubscription = { __typename?: 'Subscription', messageRead?: Array<{ __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, fullName: string, email: any, profilePicture?: string | null } | null, parentMessage?: { __typename?: 'Message', id: string, message?: string | null, status?: MessageStatus | null, messageType?: MessageType | null, read: boolean, sentBy: string, createdAt: any, updatedAt: any } | null } | null> | null };


export const GetConversationsDocument = gql`
    query GetConversations($userId: ID!, $pagination: Pagination!) {
  getConversations(userId: $userId, pagination: $pagination) {
    id
    numberOfUnreadMessagesByEmployer
    talent {
      id
      fullName
      email
      profilePicture
    }
    employer {
      id
      fullName
      email
      profilePicture
    }
    jobId
    lastMessageDetails {
      id
      message
      status
      user {
        id
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read
        sentBy
        createdAt
        updatedAt
      }
      read
      sentBy
      createdAt
      updatedAt
    }
    status
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetConversationsQuery__
 *
 * To run a query within a React component, call `useGetConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetConversationsQuery(baseOptions: Apollo.QueryHookOptions<GetConversationsQuery, GetConversationsQueryVariables> & ({ variables: GetConversationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationsQuery, GetConversationsQueryVariables>(GetConversationsDocument, options);
      }
export function useGetConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationsQuery, GetConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationsQuery, GetConversationsQueryVariables>(GetConversationsDocument, options);
        }
export function useGetConversationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetConversationsQuery, GetConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConversationsQuery, GetConversationsQueryVariables>(GetConversationsDocument, options);
        }
export type GetConversationsQueryHookResult = ReturnType<typeof useGetConversationsQuery>;
export type GetConversationsLazyQueryHookResult = ReturnType<typeof useGetConversationsLazyQuery>;
export type GetConversationsSuspenseQueryHookResult = ReturnType<typeof useGetConversationsSuspenseQuery>;
export type GetConversationsQueryResult = Apollo.QueryResult<GetConversationsQuery, GetConversationsQueryVariables>;
export const GetConversationDocument = gql`
    query GetConversation($getConversationId: ID!) {
  getConversation(id: $getConversationId) {
    id
    numberOfUnreadMessagesByEmployer
    talent {
      id
      fullName
      email
      profilePicture
    }
    employer {
      id
      fullName
      email
      profilePicture
    }
    jobId
    lastMessageDetails {
      id
      message
      status
      user {
        id
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read
        sentBy
        createdAt
        updatedAt
      }
      read
      sentBy
      createdAt
      updatedAt
    }
    status
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      getConversationId: // value for 'getConversationId'
 *   },
 * });
 */
export function useGetConversationQuery(baseOptions: Apollo.QueryHookOptions<GetConversationQuery, GetConversationQueryVariables> & ({ variables: GetConversationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, options);
      }
export function useGetConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, options);
        }
export function useGetConversationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, options);
        }
export type GetConversationQueryHookResult = ReturnType<typeof useGetConversationQuery>;
export type GetConversationLazyQueryHookResult = ReturnType<typeof useGetConversationLazyQuery>;
export type GetConversationSuspenseQueryHookResult = ReturnType<typeof useGetConversationSuspenseQuery>;
export type GetConversationQueryResult = Apollo.QueryResult<GetConversationQuery, GetConversationQueryVariables>;
export const GetMessagesDocument = gql`
    query GetMessages($conversationId: ID!, $pagination: Pagination) {
  getMessages(conversationId: $conversationId, pagination: $pagination) {
    id
    message
    status
    user {
      id
      fullName
      email
      profilePicture
    }
    messageType
    parentMessage {
      id
      message
      status
      messageType
      read
      sentBy
      createdAt
      updatedAt
    }
    read
    sentBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetMessagesQuery__
 *
 * To run a query within a React component, call `useGetMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables> & ({ variables: GetMessagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
      }
export function useGetMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
        }
export function useGetMessagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
        }
export type GetMessagesQueryHookResult = ReturnType<typeof useGetMessagesQuery>;
export type GetMessagesLazyQueryHookResult = ReturnType<typeof useGetMessagesLazyQuery>;
export type GetMessagesSuspenseQueryHookResult = ReturnType<typeof useGetMessagesSuspenseQuery>;
export type GetMessagesQueryResult = Apollo.QueryResult<GetMessagesQuery, GetMessagesQueryVariables>;
export const ClearChatHistoryDocument = gql`
    mutation ClearChatHistory($conversationId: ID!) {
  clearChatHistory(conversationId: $conversationId)
}
    `;
export type ClearChatHistoryMutationFn = Apollo.MutationFunction<ClearChatHistoryMutation, ClearChatHistoryMutationVariables>;

/**
 * __useClearChatHistoryMutation__
 *
 * To run a mutation, you first call `useClearChatHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearChatHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearChatHistoryMutation, { data, loading, error }] = useClearChatHistoryMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useClearChatHistoryMutation(baseOptions?: Apollo.MutationHookOptions<ClearChatHistoryMutation, ClearChatHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearChatHistoryMutation, ClearChatHistoryMutationVariables>(ClearChatHistoryDocument, options);
      }
export type ClearChatHistoryMutationHookResult = ReturnType<typeof useClearChatHistoryMutation>;
export type ClearChatHistoryMutationResult = Apollo.MutationResult<ClearChatHistoryMutation>;
export type ClearChatHistoryMutationOptions = Apollo.BaseMutationOptions<ClearChatHistoryMutation, ClearChatHistoryMutationVariables>;
export const DeleteConversationDocument = gql`
    mutation DeleteConversation($conversationId: ID!) {
  deleteConversation(conversationId: $conversationId)
}
    `;
export type DeleteConversationMutationFn = Apollo.MutationFunction<DeleteConversationMutation, DeleteConversationMutationVariables>;

/**
 * __useDeleteConversationMutation__
 *
 * To run a mutation, you first call `useDeleteConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConversationMutation, { data, loading, error }] = useDeleteConversationMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useDeleteConversationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConversationMutation, DeleteConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConversationMutation, DeleteConversationMutationVariables>(DeleteConversationDocument, options);
      }
export type DeleteConversationMutationHookResult = ReturnType<typeof useDeleteConversationMutation>;
export type DeleteConversationMutationResult = Apollo.MutationResult<DeleteConversationMutation>;
export type DeleteConversationMutationOptions = Apollo.BaseMutationOptions<DeleteConversationMutation, DeleteConversationMutationVariables>;
export const ReportConversationDocument = gql`
    mutation ReportConversation($conversationId: ID!, $reason: String!) {
  reportConversation(conversationId: $conversationId, reason: $reason)
}
    `;
export type ReportConversationMutationFn = Apollo.MutationFunction<ReportConversationMutation, ReportConversationMutationVariables>;

/**
 * __useReportConversationMutation__
 *
 * To run a mutation, you first call `useReportConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportConversationMutation, { data, loading, error }] = useReportConversationMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useReportConversationMutation(baseOptions?: Apollo.MutationHookOptions<ReportConversationMutation, ReportConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportConversationMutation, ReportConversationMutationVariables>(ReportConversationDocument, options);
      }
export type ReportConversationMutationHookResult = ReturnType<typeof useReportConversationMutation>;
export type ReportConversationMutationResult = Apollo.MutationResult<ReportConversationMutation>;
export type ReportConversationMutationOptions = Apollo.BaseMutationOptions<ReportConversationMutation, ReportConversationMutationVariables>;
export const MarkMessageAsReadDocument = gql`
    mutation MarkMessageAsRead($messageId: ID!, $sentBy: String!) {
  markMessageAsRead(messageId: $messageId, sentBy: $sentBy) {
    id
    message
    status
    user {
      id
      fullName
      email
      profilePicture
    }
    messageType
    parentMessage {
      id
      message
      status
      messageType
      read
      sentBy
      createdAt
      updatedAt
    }
    read
    sentBy
    createdAt
    updatedAt
  }
}
    `;
export type MarkMessageAsReadMutationFn = Apollo.MutationFunction<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;

/**
 * __useMarkMessageAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageAsReadMutation, { data, loading, error }] = useMarkMessageAsReadMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      sentBy: // value for 'sentBy'
 *   },
 * });
 */
export function useMarkMessageAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>(MarkMessageAsReadDocument, options);
      }
export type MarkMessageAsReadMutationHookResult = ReturnType<typeof useMarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationResult = Apollo.MutationResult<MarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationOptions = Apollo.BaseMutationOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;
export const MarkAllMessagesAsReadDocument = gql`
    mutation MarkAllMessagesAsRead($conversationId: ID!, $sentBy: String!) {
  markAllMessagesAsRead(conversationId: $conversationId, sentBy: $sentBy)
}
    `;
export type MarkAllMessagesAsReadMutationFn = Apollo.MutationFunction<MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables>;

/**
 * __useMarkAllMessagesAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllMessagesAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllMessagesAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllMessagesAsReadMutation, { data, loading, error }] = useMarkAllMessagesAsReadMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      sentBy: // value for 'sentBy'
 *   },
 * });
 */
export function useMarkAllMessagesAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables>(MarkAllMessagesAsReadDocument, options);
      }
export type MarkAllMessagesAsReadMutationHookResult = ReturnType<typeof useMarkAllMessagesAsReadMutation>;
export type MarkAllMessagesAsReadMutationResult = Apollo.MutationResult<MarkAllMessagesAsReadMutation>;
export type MarkAllMessagesAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($conversationId: ID!, $senderId: ID!, $message: String!, $sentBy: String!, $parentMessageId: ID) {
  sendMessage(
    conversationId: $conversationId
    senderId: $senderId
    message: $message
    sentBy: $sentBy
    parentMessageId: $parentMessageId
  ) {
    id
    message
    status
    user {
      id
      fullName
      email
      profilePicture
    }
    messageType
    parentMessage {
      id
      message
      status
      messageType
      read
      sentBy
      createdAt
      updatedAt
    }
    read
    sentBy
    createdAt
    updatedAt
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      senderId: // value for 'senderId'
 *      message: // value for 'message'
 *      sentBy: // value for 'sentBy'
 *      parentMessageId: // value for 'parentMessageId'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const CreateConversationDocument = gql`
    mutation CreateConversation($talentId: ID!, $employerId: ID!, $jobId: ID!) {
  createConversation(talentId: $talentId, employerId: $employerId, jobId: $jobId) {
    id
    talent {
      id
      fullName
      email
      profilePicture
    }
    employer {
      id
      fullName
      email
      profilePicture
    }
    jobId
    lastMessageDetails {
      id
      message
      status
      user {
        id
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read
        sentBy
        createdAt
        updatedAt
      }
      read
      sentBy
      createdAt
      updatedAt
    }
    status
    createdAt
    updatedAt
  }
}
    `;
export type CreateConversationMutationFn = Apollo.MutationFunction<CreateConversationMutation, CreateConversationMutationVariables>;

/**
 * __useCreateConversationMutation__
 *
 * To run a mutation, you first call `useCreateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMutation, { data, loading, error }] = useCreateConversationMutation({
 *   variables: {
 *      talentId: // value for 'talentId'
 *      employerId: // value for 'employerId'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useCreateConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateConversationMutation, CreateConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CreateConversationDocument, options);
      }
export type CreateConversationMutationHookResult = ReturnType<typeof useCreateConversationMutation>;
export type CreateConversationMutationResult = Apollo.MutationResult<CreateConversationMutation>;
export type CreateConversationMutationOptions = Apollo.BaseMutationOptions<CreateConversationMutation, CreateConversationMutationVariables>;
export const ConversationDeletedDocument = gql`
    subscription ConversationDeleted {
  conversationDeleted {
    id
    talent {
      id
      fullName
      email
      profilePicture
    }
    employer {
      id
      fullName
      email
      profilePicture
    }
    jobId
    lastMessageDetails {
      id
      message
      status
      user {
        id
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read
        sentBy
        createdAt
        updatedAt
      }
      read
      sentBy
      createdAt
      updatedAt
    }
    status
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useConversationDeletedSubscription__
 *
 * To run a query within a React component, call `useConversationDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConversationDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useConversationDeletedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ConversationDeletedSubscription, ConversationDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ConversationDeletedSubscription, ConversationDeletedSubscriptionVariables>(ConversationDeletedDocument, options);
      }
export type ConversationDeletedSubscriptionHookResult = ReturnType<typeof useConversationDeletedSubscription>;
export type ConversationDeletedSubscriptionResult = Apollo.SubscriptionResult<ConversationDeletedSubscription>;
export const ConversationCreatedDocument = gql`
    subscription ConversationCreated {
  conversationCreated {
    id
    talent {
      id
      fullName
      email
      profilePicture
    }
    employer {
      id
      fullName
      email
      profilePicture
    }
    jobId
    lastMessageDetails {
      id
      message
      status
      user {
        id
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read
        sentBy
        createdAt
        updatedAt
      }
      read
      sentBy
      createdAt
      updatedAt
    }
    status
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useConversationCreatedSubscription__
 *
 * To run a query within a React component, call `useConversationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConversationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useConversationCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ConversationCreatedSubscription, ConversationCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ConversationCreatedSubscription, ConversationCreatedSubscriptionVariables>(ConversationCreatedDocument, options);
      }
export type ConversationCreatedSubscriptionHookResult = ReturnType<typeof useConversationCreatedSubscription>;
export type ConversationCreatedSubscriptionResult = Apollo.SubscriptionResult<ConversationCreatedSubscription>;
export const ConversationUpdatedDocument = gql`
    subscription ConversationUpdated {
  conversationUpdated {
    id
    talent {
      id
      fullName
      email
      profilePicture
    }
    employer {
      id
      fullName
      email
      profilePicture
    }
    jobId
    lastMessageDetails {
      id
      message
      status
      user {
        id
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read
        sentBy
        createdAt
        updatedAt
      }
      read
      sentBy
      createdAt
      updatedAt
    }
    status
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useConversationUpdatedSubscription__
 *
 * To run a query within a React component, call `useConversationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConversationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useConversationUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ConversationUpdatedSubscription, ConversationUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ConversationUpdatedSubscription, ConversationUpdatedSubscriptionVariables>(ConversationUpdatedDocument, options);
      }
export type ConversationUpdatedSubscriptionHookResult = ReturnType<typeof useConversationUpdatedSubscription>;
export type ConversationUpdatedSubscriptionResult = Apollo.SubscriptionResult<ConversationUpdatedSubscription>;
export const MessageAddedDocument = gql`
    subscription MessageAdded {
  messageAdded {
    id
    message
    status
    user {
      id
      fullName
      email
      profilePicture
    }
    messageType
    parentMessage {
      id
      message
      status
      messageType
      read
      sentBy
      createdAt
      updatedAt
    }
    read
    sentBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useMessageAddedSubscription__
 *
 * To run a query within a React component, call `useMessageAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMessageAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MessageAddedSubscription, MessageAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessageAddedSubscription, MessageAddedSubscriptionVariables>(MessageAddedDocument, options);
      }
export type MessageAddedSubscriptionHookResult = ReturnType<typeof useMessageAddedSubscription>;
export type MessageAddedSubscriptionResult = Apollo.SubscriptionResult<MessageAddedSubscription>;
export const MessageReadDocument = gql`
    subscription MessageRead {
  messageRead {
    id
    message
    status
    user {
      id
      fullName
      email
      profilePicture
    }
    messageType
    parentMessage {
      id
      message
      status
      messageType
      read
      sentBy
      createdAt
      updatedAt
    }
    read
    sentBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useMessageReadSubscription__
 *
 * To run a query within a React component, call `useMessageReadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageReadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageReadSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMessageReadSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MessageReadSubscription, MessageReadSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessageReadSubscription, MessageReadSubscriptionVariables>(MessageReadDocument, options);
      }
export type MessageReadSubscriptionHookResult = ReturnType<typeof useMessageReadSubscription>;
export type MessageReadSubscriptionResult = Apollo.SubscriptionResult<MessageReadSubscription>;