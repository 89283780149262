import { gql } from "@apollo/client";

export const GET_CANDIDATE = gql`
    query GetCandidate($candidateId: ID!, $companyId: ID!) {
        getCandidate(candidateId: $candidateId, companyId: $companyId) {
            id
            appliedJob {
                companyId
                company {
                    id
                    name
                    email
                    phone
                    address
                    businessType
                    companyType
                    about
                    industry
                    website
                    numOfEmployees
                    yearOfEstablishment
                    coverPhoto
                    companyLogo
                    companyDocument
                    subscription {
                        package {
                            id
                            name
                            description
                            price
                            interval
                            jobsQuota
                            privileges
                            planCode
                            planId
                            createdAt
                            updatedAt
                            deletedAt
                        }
                        status
                        expiryDate
                    }
                    meta {
                        suspendedAt
                        suspendedBy
                        rejectionReason
                        suspensionReason
                        companyRequestStatus
                        status
                        isVerified
                    }
                    socials {
                        id
                        name
                        link
                    }
                    createdAt
                    updatedAt
                }
                id
                title
                type
                about
                mode
                salary {
                    maximum
                    frequency
                    minimum
                    currency
                }
                requirements
                skills
                responsibilities
                benefits
                location
                status
                vacancies
                openDate
                closeDate
                urgent
                applicationCount
                comments {
                    id
                    comment
                    userId
                    jobId
                    createdAt
                    updatedAt
                }
                likes {
                    id
                    userId
                    jobId
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            companyId
            candidateId
            evaluation {
                rating
                comment
            }
            applicationDetails {
                firstName
                lastName
                email
                phoneNumber
                resume
                linkdln
                portfolio
            }
            status
            appliedAt
            createdAt
            updatedAt
            talent {
                id
                fullName
                email
                phoneNumber
                profileSummary
                avatar
                address
                socialLinks {
                    id
                    name
                    link
                }
                education {
                    id
                    programme
                    school
                    startDate
                    endDate
                    isCurrentlyStudying
                }
                references {
                    id
                    fullName
                    company
                    position
                    email
                    phoneNumber
                }
                professionalBg {
                    id
                    companyName
                    jobTitle
                    startDate
                    endDate
                    isCurrentJob
                    skills
                }
                portfolio {
                    id
                    website
                    resume
                }
                skills
                achievements {
                    id
                    title
                    description
                    date
                }
                acceptedTermsAndConditions
                loginMeta {
                    lastLoginAt
                    lastLoginIp
                    lastLoginLocation
                    createdAt
                    updatedAt
                }
                accountMeta {
                    isPhoneNumberVerified
                    isEmailVerified
                    onBoardingStatus
                    authType
                    status
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
        }
    }
`;

//
export const GET_CANDIDATES = gql`
    query GetCandidates($filter: CandidateFilter, $pagination: Pagination, $condition: Condition, $sort: AppliedJobSort) {
        getCandidates(filter: $filter, pagination: $pagination, condition: $condition, sort: $sort) {
            rows {
                id
                appliedJob {
                    companyId
                    company {
                        id
                        name
                        email
                        phone
                        address
                        businessType
                        companyType
                        about
                        industry
                        website
                        numOfEmployees
                        yearOfEstablishment
                        coverPhoto
                        companyLogo
                        companyDocument
                        subscription {
                            package {
                                id
                                name
                                description
                                price
                                interval
                                jobsQuota
                                privileges
                                planCode
                                planId
                                createdAt
                                updatedAt
                                deletedAt
                            }
                            status
                            expiryDate
                        }
                        meta {
                            suspendedAt
                            suspendedBy
                            rejectionReason
                            suspensionReason
                            companyRequestStatus
                            status
                            isVerified
                        }
                        socials {
                            id
                            name
                            link
                        }
                        createdAt
                        updatedAt
                    }
                    id
                    title
                    type
                    about
                    mode
                    salary {
                        maximum
                        frequency
                        minimum
                        currency
                    }
                    requirements
                    skills
                    responsibilities
                    benefits
                    location
                    status
                    vacancies
                    openDate
                    closeDate
                    urgent
                    applicationCount
                    comments {
                        id
                        comment
                        userId
                        jobId
                        createdAt
                        updatedAt
                    }
                    likes {
                        id
                        userId
                        jobId
                        createdAt
                        updatedAt
                    }
                    createdAt
                    updatedAt
                }
                companyId
                candidateId
                evaluation {
                    rating
                    comment
                }
                applicationDetails {
                    firstName
                    lastName
                    email
                    phoneNumber
                    resume
                    linkdln
                    portfolio
                }
                status
                appliedAt
                createdAt
                updatedAt
                talent {
                    id
                    fullName
                    email
                    phoneNumber
                    profileSummary
                    avatar
                    address
                    socialLinks {
                        id
                        name
                        link
                    }
                    education {
                        id
                        programme
                        school
                        startDate
                        endDate
                        isCurrentlyStudying
                    }
                    references {
                        id
                        fullName
                        company
                        position
                        email
                        phoneNumber
                    }
                    professionalBg {
                        id
                        companyName
                        jobTitle
                        startDate
                        endDate
                        isCurrentJob
                        skills
                    }
                    portfolio {
                        id
                        website
                        resume
                    }
                    skills
                    achievements {
                        id
                        title
                        description
                        date
                    }
                    acceptedTermsAndConditions
                    loginMeta {
                        lastLoginAt
                        lastLoginIp
                        lastLoginLocation
                        createdAt
                        updatedAt
                    }
                    accountMeta {
                        isPhoneNumberVerified
                        isEmailVerified
                        onBoardingStatus
                        authType
                        status
                        createdAt
                        updatedAt
                    }
                    createdAt
                    updatedAt
                }
            }
            count
        }
    }
`;
