import { CheckIcon } from "@heroicons/react/24/outline";

type Props = { features: string[]; setCurrentPage: (value: "Plan" | "Subscribe" | "Contact") => void; name: string; description: string };

const CurrentPlan = ({ features, setCurrentPage, name, description }: Props) => {
    return (
        <div className="w-full">
            <div className="">
                <div className="flex my-5 w-full flex-col justify-center items-center">
                    <h3 className="font-semibold text-md">My Current Subscription</h3>
                    <p className="mb-5 text-xs">You're currently subscribed to the {name} plan</p>
                    <div className="w-full xl:w-6/12 border border-black/5 rounded-lg flex justify-between items-center h-16 gap-2 ">
                        <div className="w-8/12 flex flex-col justify-center items-center h-full  rounded-lg bg-green-900">
                            <h3 className="text-sm font-semibold leading-4 text-white ">{name}</h3>
                            <p className="text-[10px] text-center text-white mx-2 ">{description}</p>
                        </div>
                        <div className="w-4/12 flex flex-col justify-center items-center h-full ">
                            <button className="md:text-xs font-medium text-white px-3 py-2 bg-green-700 rounded text-[11px]" onClick={() => setCurrentPage("Subscribe")}>
                                Change Plan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-3 bg-slate-50 border rounded-md ">
                <div className=" py-2 px-5 border-black/5">
                    <h3 className="text-sm font-semibold">FEATURE</h3>
                </div>
                {features?.map((info, index) => (
                    <div className="border-t h-12 px-5 border-black/5 flex justify-between items-center space-x-2" key={index}>
                        <h3 className="text-xs font-medium">{info}</h3>
                        <span className="rounded-full p-1 bg-primary-300">
                            <CheckIcon className="h-2 w-2 text-white font-bold" />
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CurrentPlan;
