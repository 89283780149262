import { useNavigate } from "react-location";
import { clearAuth } from "@/apollo/cache/auth";
import logo from "@/assets/images/logo-orange.png";
import { useCallback } from "react";
import { Modal } from "@/components";

const SignoutModal = ({ visible, onClose }: any) => {
    const navigate = useNavigate();

    const handleSignOut = useCallback(() => {
        onClose();
        clearAuth(); // Ensure clearAuth completes
        navigate({ to: "/" }); // Navigate to login
    }, [onClose, clearAuth, navigate]);

    if (!visible) return null;

    return (
        <Modal open={visible} setOpen={onClose} title={""} hideActions={true}>
            <div className="w-full h-full py-8   flex flex-col justify-center items-center gap-y-3">
                <div className="w-16 h-16 bg-primary rounded-full flex justify-center items-center">
                    <img src={logo} alt="logo" className="w-11 h-auto object-contain" />
                </div>
                <p className="max-w-[25rem] font-montserrat text-gray-400 text-sm leading-[1.602rem] tracking-[0.1px] text-center px-3 md:px-0 mb-4 md:mb-0 ">
                    Thank you for visiting our website! We hope you found what you were looking for, and we encourage you to follow us on all social media platforms!
                </p>
                <h3 className="max-w-[25rem] font-montserrat font-semibold text-lg leading-[1.886rem] tracking-[0.1px] text-center px-3 md:px-0 mb-6">Adjuma - Connecting millions of Africans to millions of jobs!!</h3>

                <button className="w-[173px] font-semibold py-3 font-montserrat text-sm capitalize leading-5 tracking-[0.1px] text-center bg-primary rounded-lg text-[#D9D9D9]" type="button" onClick={handleSignOut}>
                    Back to login
                </button>
            </div>
        </Modal>
    );
};

export default SignoutModal;
