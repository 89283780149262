import { ApplicationStatus, RejectApplicationMutation, RejectApplicationMutationVariables } from "@/apollo/generated/types";
import { REJECT_CANDIDATE } from "@/apollo/graphql/mutations/candidate";
import { useMutation } from "@apollo/client";
import { HandThumbDownIcon } from "@heroicons/react/24/outline";
import { HandThumbDownIcon as HandThumbDownIconSolid } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { Tooltip } from "react-tooltip";

type Props = { applicationId: string; jobId: string; status: ApplicationStatus | undefined | null };

const RejectCandidateButton = ({ applicationId, jobId, status }: Props) => {
    const [rejectCandidate, { loading }] = useMutation<RejectApplicationMutation, RejectApplicationMutationVariables>(REJECT_CANDIDATE, { refetchQueries: ["GetCandidates", "GetCandidate"] });
    const handleAccept = async () => {
        await rejectCandidate({ variables: { applicationId: applicationId, jobId: jobId } })
            .then(() => {
                toast(
                    JSON.stringify({
                        type: "success",
                        title: "Candidate rejected successfully",
                    })
                );
            })
            .catch(() => {
                toast(
                    JSON.stringify({
                        type: "error",
                        title: "Something wrong happened",
                    })
                );
            });
    };
    return (
        <button disabled={status === ApplicationStatus.Rejected} onClick={handleAccept} className="p-1 rounded-full hover:bg-gray-300 hover:text-black" data-tooltip-delay-show={100} data-tooltip-id="global-tooltip" data-tooltip-content="Reject Candidate">
            <Tooltip
                style={{
                    fontSize: "10px",
                    fontWeight: "600",
                    backgroundColor: "#101828",
                    color: "#fff",
                    borderRadius: "20px",
                    marginTop: "10px",
                    height: "10px",
                }}
                anchorSelect={`#reject-candidate-${applicationId}`}
                content={`Accept`}
                className="z-[3]"
            />
            {status === ApplicationStatus.Rejected ? <HandThumbDownIconSolid className="h-5 w-5 text-primary-500" /> : <HandThumbDownIcon className="h-5 w-5" />}
        </button>
    );
};

export default RejectCandidateButton;
