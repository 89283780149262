// eslint-disable-next-line @typescript-eslint/no-var-requires
import { Link, useNavigate, useSearch } from "react-location";
import signInImage from "@/assets/images/signin-illustration.svg";
import { ChangeEvent, useState } from "react";
import { useMutation } from "@apollo/client";
import { GoogleAuthProvider, auth, signInWithPopup } from "@/config/firebase";
import toast from "react-hot-toast";
import { Button, TextInput } from "@/components";
import { FcGoogle } from "react-icons/fc";
import { SIGNUP } from "@/constants/page-paths";
import { setAuth } from "@/apollo/cache/auth";
import { useLocalStorage } from "react-use";
import { LOGIN_USER_MUTATION } from "@/apollo/graphql/mutations/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import ButtonLoader from "@/components/loaders/button";
import { LocationGenerics } from "@/router/location";

export default function SignIn() {
    const [termsChecked, setTermsChecked] = useState(false);
    const search = useSearch<LocationGenerics>();
    const [eyeClick, setEyeClick] = useState(false);
    const [socialAuthLoading, setSocialAuthLoading] = useState(false);
    const [_forgotStore, setForgotStore] = useLocalStorage<{
        email?: string;
        tab: string;
        token?: string;
    }>("forgot-store", { email: "", tab: "send-code", token: "" });

    const navigate = useNavigate();

    const [signin, { loading }] = useMutation(LOGIN_USER_MUTATION);

    const { handleSubmit, ...signinForm } = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Email is invalid").required("Personal email is required"),
            password: Yup.string().min(8, "Password must be at least 8 characters long").required("Your password is required"),
        }),
        onSubmit: async (values) => {
            await signin({
                variables: {
                    email: values.email,
                    password: values.password,
                },
            })
                .then(({ data }) => {
                    if (data.loginEmployer) {
                        const { user: employer, token } = data.loginEmployer;
                        toast(
                            JSON.stringify({
                                type: "success",
                                title: `Welcome Back ${employer.fullName.split(" ")[0]}`,
                            })
                        );
                        setAuth({
                            token,
                            user: employer,
                        });

                        return navigate({
                            to: search?.redirect ?? "/",
                        });
                    } else {
                        toast(
                            JSON.stringify({
                                type: "error",
                                title: `Something wrong happened`,
                            })
                        );
                    }
                })
                .catch((err) => {
                    toast(
                        JSON.stringify({
                            type: "error",
                            title: err?.message,
                        })
                    );
                    setSocialAuthLoading(false);
                });
        },
    });

    const handleSocialAuth = async () => {
        setSocialAuthLoading(true);

        const provider = new GoogleAuthProvider();

        const googleUser: any = await signInWithPopup(auth, provider);

        await signin({
            variables: {
                email: googleUser.user.email,
                password: googleUser.user.uid,
            },
        })
            .then(({ data }) => {
                setSocialAuthLoading(false);
                const { user, token } = data.loginEmployer;
                toast(
                    JSON.stringify({
                        type: "success",
                        title: `Welcome Back ${user.fullName.split(" ")[0]}`,
                    })
                );
                setAuth({
                    token,
                    user,
                });
                navigate({
                    to: "/",
                });
            })
            .catch((err) => {
                toast(
                    JSON.stringify({
                        type: "error",
                        title: err?.message,
                    })
                );
                setSocialAuthLoading(false);
            });
    };
    return (
        <>
            <div className="flex justify-between items-start w-full">
                <div className="w-full  md:w-1/2 flex justify-center items-start pt-10">
                    <div className="max-w-md w-full px-5">
                        <div>
                            <h1 className="text-center md:text-left font-montserrat font-medium text-3xl mb-1">Welcome Back</h1>
                        </div>
                        <div>
                            <p className="text-sm font-montserrat text-center md:text-left text-gray_signup">Please enter your credentials below to continue</p>
                        </div>
                        <div className="space-y-3 w-full mt-10">
                            <div className="space-y-4">
                                <div className="flex flex-col items-start justify-start space-y-1">
                                    <label className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006px] mb-1" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        id="email"
                                        placeholder="Enter your email"
                                        name="email"
                                        type="email"
                                        value={signinForm.values.email}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => signinForm.setFieldValue("email", e.target.value)}
                                        autoComplete="email"
                                        className=" text-gray-900 text-xs border w-full h-10 px-2 rounded-md border-gray-500 outline-primary-500  ring-primary-500"
                                    />
                                    {signinForm.errors.email && <div className="text-xs text-red-500 mt-1">{signinForm.errors.email}</div>}
                                </div>
                                <div className="flex flex-col items-start justify-start space-y-1">
                                    <label className="text-[12px] font-[400] text-black-2 capitalize leading-[14.4px] tracking-[0.1px] mb-1" htmlFor="password">
                                        Password
                                    </label>
                                    <div className="flex items-center justify-between w-full  overflow-hidden border  border-gray-500 rounded-md px-2 h-10 hover:ring-1 ring-primary-500">
                                        <input
                                            id="password"
                                            type={eyeClick ? "text" : "password"}
                                            placeholder="Enter your password"
                                            name="password"
                                            value={signinForm.values.password}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => signinForm.setFieldValue("password", e.target.value)}
                                            autoComplete="current-password"
                                            className="text-gray-900 text-xs border-0 w-full h-full rounded-md  outline-none  ring-0 flex-1"
                                        />
                                        <div className="cursor-pointer mt-1" onClick={() => setEyeClick(!eyeClick)}>
                                            {eyeClick ? <LuEye className="w-4 h-4" /> : <AiOutlineEyeInvisible className="w-4 h-4" />}
                                        </div>
                                    </div>
                                    {signinForm.errors.password && <p className="text-xs text-red-500">{signinForm.errors.password}</p>}
                                </div>
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center mt-6 mb-6">
                                        {/* <input
                                            onChange={() => {
                                                setTermsChecked(!termsChecked);
                                            }}
                                            type="checkbox"
                                            checked={termsChecked}
                                            className=" w-3 h-3 mr-2 cursor-pointer rounded-[4px] border border-gray-300 accent-primary-500 bg-primary-500 focus:ring-primary-500 text-primary-500"
                                        />
                                        <label className="text-black text-[14px] leading-[20px] font-manrope font-[400]" htmlFor="remember">
                                            Remember for 30 days
                                        </label> */}
                                    </div>

                                    <Link to="/forgot-password" className="text-primary-500 text-[14px] leading-[20px] font-manrope font-[400]">
                                        Forgot password ?
                                    </Link>
                                </div>
                            </div>

                            <div className="mt-5 w-full">
                                <Button type="button" onClick={handleSubmit} size="lg" width="full">
                                    {loading ? <ButtonLoader title="Signing in..." /> : " Sign in"}
                                </Button>
                            </div>

                            <div className="relative my-5">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-200" />
                                </div>
                                <div className="relative flex justify-center text-sm font-medium leading-6">
                                    <span className="bg-white px-6 text-gray-900">OR</span>
                                </div>
                            </div>

                            <div className="">
                                <button
                                    type="button"
                                    onClick={handleSocialAuth}
                                    className="w-full h-[42px] flex justify-center items-center gap-x-2  rounded-md border  border-primary py-2 text-sm text-gray_signup leading-6  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                                    <FcGoogle className="text-center w-5 h-5" />
                                    {socialAuthLoading ? <ButtonLoader title="Authenticating..." color="text-primary-500" /> : "Sign in with Google"}
                                </button>
                            </div>
                        </div>

                        <div className="mt-5 mb-5">
                            <p className="text-center  font-montserrat text-sm">
                                Don't have an account?
                                <span className="ml-2 text-primary text-sm font-semibold underline">
                                    <Link to={SIGNUP}> Sign up</Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-1/2 hidden md:flex justify-center items-start pt-10">
                    <div className="flex flex-col items-center">
                        <div className="flex flex-col gap-y-3">
                            <div>
                                <h2 className="text-center font-montserrat text-3xl font-medium">
                                    <span className="whitespace-nowrap">Access Your Account for</span>
                                    <br />
                                    <span className="whitespace-nowrap"> Seamless Navigation</span>
                                </h2>
                            </div>
                            <p className="text-center">Your Gateway to Success – Log in Now</p>
                        </div>
                        <div className="mt-10 flex justify-center">
                            <img className="" src={signInImage} alt="signin" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
