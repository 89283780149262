import { useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import { Package } from "@/apollo/generated/types";
import { Button, Show } from "@/components";
import EnterprisePage from "@/components/enterprise-page";
import DefaultLoader from "@/components/loaders/default-loader";
import { usePackages } from "@/hooks";
import PricingItem from "@/pages/post-job/components/PricingItem";
import { hasPermission, PERMISSIONS } from "@/permissions";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import CurrentPlan from "./CurrentPlan";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";

type Props = {};

const Subscription = (props: Props) => {
    const currentUserPermissions = useCurrentUserPermissions();
    const [currentPage, setCurrentPage] = useState<"Subscribe" | "Contact" | "Plan">("Plan");
    const colors = ["bg-blue-500", "bg-green-500", "bg-violet-500", "bg-yellow-500", "bg-indigo-500"];
    const { packages, loading: packageLoading } = usePackages();
    const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
    const employer = useCurrentUser();

    useEffect(() => {
        setSelectedPackage(employer?.company?.subscription?.package || null);
    }, [employer?.id]);
    return (
        <div>
            {currentPage === "Contact" ? (
                <EnterprisePage
                    setTab={() => {
                        setCurrentPage("Plan");
                    }}
                />
            ) : (
                <>
                    {packageLoading ? (
                        <div className="w-full h-full">
                            <DefaultLoader />
                        </div>
                    ) : !_.isEmpty(selectedPackage) && currentPage === "Plan" ? (
                        <CurrentPlan name={selectedPackage?.name || ""} description={selectedPackage?.description} setCurrentPage={setCurrentPage} features={[`Upto ${selectedPackage?.jobsQuota} jobs postings`].concat(selectedPackage?.privileges)} />
                    ) : (
                        <div className="w-full h-full overflow-scroll overflow-y-scroll no-scrollbar">
                            {!_.isEmpty(selectedPackage) && (
                                <div className="w-full flex justify-start mt-5 px-2 py-2">
                                    <button onClick={() => setCurrentPage("Plan")} className="h-10 w-full text-xs font-bold flex justify-between items-center border rounded-xl text-black bg-amber-400 hover:bg-amber-600 gap-3 px-2">
                                        <span className="bg-white p-1 rounded-lg">
                                            <ChevronDoubleLeftIcon className="h-4 w-4" />
                                        </span>
                                        <span className="p-1 bg-white rounded-lg"> Go To Current Plan</span>
                                    </button>
                                </div>
                            )}
                            <div className="w-full mt-5">
                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.DEDICATED_SUPPORT)}>
                                    <div className="flex my-5 w-full flex-col justify-center items-center">
                                        <h3 className="font-semibold mb-2 text-sm">Call for Custom Quote</h3>
                                        <div className="w-full xl:w-6/12 border border-black/5 rounded-lg flex justify-between items-center h-16 ">
                                            <div className="w-7/12 flex flex-col justify-center items-center h-full  rounded-lg bg-amber-600">
                                                <h3 className="text-xs font-semibold leading-4 text-white">ENTERPRISE</h3>
                                                <p className="text-[10px] text-center font-semibold text-white">Elite solution for scalable hiring needs.</p>
                                            </div>
                                            <div className="w-5/12 flex flex-col justify-center items-center h-full ">
                                                <button className="text-xs font-semibold text-white px-3 py-2 bg-amber-400 rounded" onClick={() => setCurrentPage("Contact")}>
                                                    Contact Sales
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Show>
                                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 ">
                                    {packages?.map((data, index) => {
                                        if (data?.id === employer?.company?.subscription?.package?.id) {
                                            return null;
                                        } else {
                                            return (
                                                <PricingItem planCode={data?.planCode || ""} id={data?.id} key={data?.id} color={colors[index % 4]} title={data?.name} description={data?.description} interval={data?.interval || ""} price={data?.price} features={[`Upto ${data?.jobsQuota} jobs postings`].concat(data?.privileges)} />
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Subscription;
