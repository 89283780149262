import emailFooter from "@/assets/images/email foot.png";
import logo from "@/assets/images/logo.png";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";

import Wrapper from "@/components/wrapper";
import { useNotifications } from "@/hooks/useNotifications";
import { useCurrentUser } from "@/apollo/cache/auth";
import _ from "lodash";
import { useUrlState } from "@/utils";
import NotificationDetail from "./components/NotificationDetail";
import NotificationList from "./components/NotificationList";

const NotificationsPage = () => {
    const employer = useCurrentUser();
    const [notificationId] = useUrlState("id");
    const { notifications, loading } = useNotifications({ variables: { filter: { userId: !_.isEmpty(employer?.id) ? { eq: employer?.id } : undefined, userType: { eq: "Employer" } }, pagination: {} } });

    return (
        <Wrapper>
            <main className="w-full h-full flex justify-start items-start lg:pr-10 md:pr-5 relative">
                <div className="block md:hidden h-full w-full ">{!_.isEmpty(notificationId) ? <NotificationDetail /> : <NotificationList loading={loading} notifications={notifications?.rows} />}</div>
                <div className="hidden md:flex w-full h-full  justify-between">
                    <div className=" h-full w-1/2">
                        <NotificationList loading={loading} notifications={notifications?.rows} />
                    </div>
                    <div className="h-full w-1/2">
                        {!_.isEmpty(notificationId) ? (
                            <div className="w-full">
                                <NotificationDetail />
                            </div>
                        ) : (
                            <div className="m-5">
                                <div className=" rounded-xl ring-1 ring-gray-300 p-3">
                                    <img src={emailFooter} alt="email-footer" />

                                    <div className="flex justify-start items-center w-full gap-3 mt-4 ">
                                        <a href={FACEBOOK}>
                                            <BiLogoFacebookCircle className="text-gray-400 w-7 h-7" />
                                        </a>
                                        <a href={TWITTER}>
                                            <FaXTwitter className="text-gray-400 w-6 h-6" />
                                        </a>
                                        <a href={INSTAGRAM}>
                                            <FaInstagram className="text-gray-400 w-6 h-6" />
                                        </a>
                                        <a href={LINKEDIN}>
                                            <FaLinkedin className="text-gray-400 w-6 h-6" />
                                        </a>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-y-2 items-end  py-5 mt-6">
                                    <img src={logo} alt="logo" className="object-contain h-5 w-20 " />

                                    <h5 className="font-medium text-sm text-gray-400">Contact Support</h5>

                                    <div className="flex justify-end items-center w-full gap-5">
                                        <a href={FACEBOOK}>
                                            <BiLogoFacebookCircle className="text-gray-400 w-5 h-5" />
                                        </a>
                                        <a href={TWITTER}>
                                            <FaXTwitter className="text-gray-400 w-4 h-4" />
                                        </a>
                                        <a href={INSTAGRAM}>
                                            <FaInstagram className="text-gray-400 w-4 h-4" />
                                        </a>
                                        <a href={LINKEDIN}>
                                            <FaLinkedin className="text-gray-400 w-4 h-4" />
                                        </a>
                                    </div>

                                    <p className="font-medium text-sm text-gray-400">&copy; Copyright {new Date().getFullYear()}, Adjuma.io</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </Wrapper>
    );
};

export default NotificationsPage;
